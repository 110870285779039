<template>
  <master-layout bgColor="white">
    <img src="assets/images/obt-logo-primary-color.svg" class="logo"/>

    <ion-card>
      <ion-item>
        <ion-label position="fixed"><b>Üzemeltető</b></ion-label>
        <ion-label class="rightAlign">Országos Bortúra Kft.</ion-label>
      </ion-item>
      <ion-item>
        <ion-label position="fixed"><b>Verzió</b></ion-label>
        <ion-label class="rightAlign">{{ version }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-label position="fixed"><b>Hibabejelentés</b></ion-label>
        <ion-label class="rightAlign"><a href="mailto:hello@orszagosbortura.hu">hello@orszagosbortura.hu</a></ion-label>
      </ion-item>
      <ion-item>
        <ion-label position="fixed"><b>Adatvédelmi<br/>tájékoztató</b></ion-label>
        <ion-label class="rightAlign"><a href="https://www.orszagosbortura.hu/obt-adatvedelmi-tajekoztato-altalanos.pdf" target="_blank">Frissítve: 2021.10.12.</a></ion-label>
      </ion-item>
    </ion-card>

    <p class="smallText pCenter">© 2021 – Minden jog fenntartva!</p>
    <ion-button class="bottomButton" expand="block" fill="transparent" @click="$router.go(-1)">Vissza</ion-button>
    <div class="bottomSpacer"></div>

  </master-layout>
</template>

<script>
import { isPlatform } from '@ionic/vue'
import {
  IonCard, IonItem, IonLabel, IonButton
} from '@ionic/vue'

export default {
  components: {
    IonCard, IonItem, IonLabel, IonButton
  },
  computed: {
    version() {
      if(isPlatform('android')){
        return '0.0.5'
      } else {
        return '1.2.8'
      }
    }
  }
}
</script>

<style scoped>
ion-button{
  margin: 10px 40px;
}
.rightAlign{
  text-align: right;
}
.logo{
  display: block;
  width: 40%;
  margin: 40px auto 30px auto;
}
p{
  margin: 20px 0;
  color: #666666;
}
ion-label{
  font-size: 14px !important;
}
a:hover{
  color: inherit;
  text-decoration: underline;
}
</style>
